import BaseNotification from '../../Shared/Notifications/Models/BaseNotification.interface';
import BaseNotificationStyles, {
  StyledContentContainer,
} from './BaseNotificationStyles';

function KexNotificationBanner({ message }: BaseNotification) {
  return (
    <>
      {message ? (
        <BaseNotificationStyles>
          <StyledContentContainer>
            <div>{message}</div>
          </StyledContentContainer>
        </BaseNotificationStyles>
      ) : (
        ''
      )}
    </>
  );
}

export default KexNotificationBanner;
