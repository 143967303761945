import { styled } from '../../stitches.config';
import { timings } from '../../Theme/Settings/animation';
import {
  animationTranslateInMobile,
  animationTranslateInDesktop,
} from '../../Theme/Settings/keyframes';

const BaseNotificationStyles = styled('div', {
  position: 'fixed',
  t: 0,
  l: 0,
  r: 0,
  mt: 23,
  animationIterationCount: 1,
  animation: `${animationTranslateInMobile} ${timings.five}`,
  '@mediaMinLarge': {
    pr: 9,
    animation: `${animationTranslateInDesktop} ${timings.five}`,
  },
  MozAnimationDirection: 'forward',
  w: '100%',
  zIndex: '$Notification',
  backgroundColor: '$black',
  color: '$white',
  boxShadow:
    '0 2px 32px 2px rgba(59, 72, 80, 0.05), 0 0 4px 0 rgba(72, 72, 72, 0.15)',
  textAlign: 'center',
});

export const StyledContentContainer = styled('div', {
  w: '100%',
  maxWidth: '$contentMaxWidth',
  position: 'relative',
  mx: 'auto',
  my: 4,
  '@mediaMinLarge': {
    //Chevron pointing to minicart
    '&:before': {
      content: '""',
      position: 'absolute',
      t: -6,
      r: 2,
      float: 'right',
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderBottomColor: '$accent',
      bw: 2,
      btw: 0,
    },
  },
});

export default BaseNotificationStyles;
